// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
	$name: "icomoon",
	$file: "icomoon",
	$weight: 400,
	$style: normal,
	$type: woff
);

@include font-face(
	$name: "barlowlight",
	$file: "barlow-light-webfont",
	$weight: 300,
	$style: normal,
	$type: woff
);

@include font-face(
	$name: "barlowlight",
	$file: "barlow-semibold-webfont",
	$weight: 600,
	$style: normal,
	$type: woff
);
