// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

$desktopHover: true; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background:none;
		color: $secondary;
	}
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $dark;

	@include breakpoint($break) {
		background:none;
		color: $color3;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, arrow-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				padding:rem($baseGap) 0 rem(80px);

				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							padding:rem($baseGap);
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								display:block;
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		nav {
			ul {
				&.navi {
					padding:0;
					
					li {
						font-size:rem(14px);

						@include breakpoint(huge) {
							font-size:rem(16px);
						}

						&.startseite, &.karriere, &.aktuelles, &.kontaktAnmeldung, &.anfahrt, &.impressum, &.datenschutzerklaerung {
							display:none; 
						}

						&.startseite {
							@include breakpoint(full) {
								display:block;
							}
						}

						&.hasSub {

							span:after {
								margin-left:0;
							}

							.sub {
								left:auto;
								right:-1px;
								text-align:right;
								background:$secondary;
								padding:rem(10px) 0;

								body.firefox & {
									right:0;
								}

								a {
									padding:rem(10px) rem(23px) !important;
									color:$light;

									&:hover {
										color:rgba($light,.6);
									}

									&.active {
										background:$color3;
									}
								}
							}
						}
						&.nav-back {
						}

						a, span {
							padding:0 !important;
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.phone {
		a {
			display:inline-block;
			color:$light;
			text-decoration:none;
		}
	}
}
