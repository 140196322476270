
// HEADER
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

#pageHeader {
	position: relative;
	width: 100%;
	margin-bottom:rem(30px);
	position: relative;
	padding:rem(80px) 0 0 0;

	@include breakpoint(large) {
		margin-bottom:rem(50px);
	}

	@include breakpoint(giant) {
		margin-bottom:rem(100px);
		padding:rem(160px) 0 0 0;
	}

	@include breakpoint(huge) {
		margin-bottom:rem(130px);
	}

	body.index & {
		margin-bottom:rem(50px);

		@include breakpoint(large) {
			margin-bottom:rem(150px);
		}

		@include breakpoint(giant) {
			margin-bottom:rem(250px);
		}
	}

	.branding {
		margin-bottom:rem(30px);

		@include breakpoint(giant) {
			display:none;
		}
	}

	.claim {
		min-height:rem(100px);
		background: {
	        image:image-url("layout/petras-und-steffis-fahrschulen-1.jpg");
	        position:center;
	        repeat:no-repeat;
	        size:cover;
	    };
	    position:relative;
	    color:$light;
	    text-align:center;
	    padding:rem(30px) rem($baseGap);
	    display:none;

	    @include breakpoint(giant) {
	    	text-align:left;
	    	display:flex;
	    	align-items:flex-end;
	    	padding:rem(60px) 0 rem(40px);

	    	body.internetExplorer & {
	    		padding-top:rem(370px);
	    	}
	    }

	    body.index & {
	    	display:block;

	    	@include breakpoint(giant) {
	    		display:flex;
	    		min-height:rem(620px);

		    	body.internetExplorer & {
		    		padding-top:rem(370px);
		    	}
	    	}
	    }

	    &:after {
	    	content:'';
	    	position:absolute;
	    	top:0;
	    	left:0;
	    	right:0;
	    	bottom:0;
	    	background:rgba($secondary,.7);
	    	z-index:1;

	    	@include breakpoint(giant) {
	    		background:rgba($secondary,.5);
	    	}

	    	body.index & {
		    	@include breakpoint(giant) {
		    		background:linear-gradient(to bottom, rgba($secondary,0) 20%, rgba($secondary,.7) 100%);
		    	}
	    	}
	    }

	    .row {
	    	position:relative;
	    	z-index:2;
	    }

	    p {
	    	font-size:rem(15px);
	    	line-height:rem(25px);
	    	margin-bottom:rem(10px);

	    	@include breakpoint(giant) {
		    	font-size:rem(22px);
		    	line-height:rem(32px);
	    	}

	    	&.big {
		    	font-size:rem(16px);
		    	line-height:rem(26px);

		    	@include breakpoint(tiny) {
			    	font-size:rem(20px);
			    	line-height:rem(30px);
		    	}

		    	@include breakpoint(giant) {
			    	font-size:rem(46px);
			    	line-height:rem(56px);
			    	margin-bottom:rem(35px);
		    	}
	    	}
	    }
	}

	.teaser {
		display:block;

		.col {
			display:flex;
			justify-content:space-between;
			flex-wrap:wrap;

			a {
				background:#ccc;
				margin-top:rem(3px);
				text-decoration:none;
				background: {
			        position:center;
			        repeat:no-repeat;
			        size:cover;
			    };
			    text-align:center;
			    position:relative;
			    color:$light;
			    font-size:rem(16px);
			    line-height:rem(26px);
			    font-weight:600;
			    width:100%;
			    padding:rem(30px) 0;
			    transition:.5s;

			    @include breakpoint(tiny) {
			    	width:calc(50% - 2px);
			    	margin-top:rem(4px);
			    }

			    @include breakpoint(large) {
			    	width:calc(25% - 3px);
			    	margin-top:rem(3px);
			    	padding:rem(80px) 0 rem(20px);
			    }

				@include breakpoint(giant) {
					padding:rem(250px) 0 rem(30px);
				}

				&:first-child {
					background-image:image-url("layout/teaser-fuehrerscheinklassen.webp");
				}

				&:nth-child(2) {
					background-image:image-url("layout/teaser-motorradausbildung.webp");
				}

				&:nth-child(3) {
					background-image:image-url("layout/teaser-fahrlehrerausbildung.webp");
				}

				&:nth-child(4) {
					background-image:image-url("layout/teaser-karriere.webp");
				}

				&:nth-child(5) {
					background-image:image-url("layout/teaser-fuhrpark.webp");
				}

				&:nth-child(6) {
					background-image:image-url("layout/teaser-asf-fes-kurse.webp");
				}

				&:nth-child(7) {
					background-image:image-url("layout/teaser-team.webp");
				}

				&:nth-child(8) {
					background-image:image-url("layout/teaser-standorte.webp");
				}

				&:after {
					content:'';
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
			    	background:rgba($secondary,.7);
			    	z-index:0;
			    	transition:.5s;

			    	@include breakpoint(giant) {
			    		background:linear-gradient(to bottom, rgba($secondary,0) 20%, rgba($secondary,.7) 100%);
			    	}
				}

				&:hover {

					@include breakpoint(giant) {
						padding:rem(230px) 0 rem(50px);
					}

					&:after {
						@include breakpoint(giant) {
							opacity:.8;
						}
					}

				}

				span {
					position:relative;
					z-index:1;
				}

				i {
					vertical-align:text-bottom;
				}
			}
		}
	}
}

#pageNavi {

	@include breakpoint(giant) {
		padding-top:rem(27px);
		height:rem(160px);
		transition:none;
	}

	body.scroll & {
		@include breakpoint(giant) {
			box-shadow:0 0 40px 0 rgba(#000,.08);
		}
	}

	.col {
		padding-left:0;
		padding-right:0;

		@include breakpoint(giant) {
			padding-left:rem($baseGap);
			padding-right:rem($baseGap);
		}
	}

	.branding {
		display:none;

		@include breakpoint(giant) {
			display:inline-block;
		}

	}

	ul.data {
		display:none;

		@include breakpoint(giant) {
			display:flex;
			justify-content:flex-end;
			align-items:center;
			color:$color3;
			padding-bottom:rem(16px);
		}

		li {
			padding-left:rem(30px);

			a {
				text-decoration:none;
				color:$color3;
				transition:.5s;

				&:hover {
					@include breakpoint(giant) {
						color:$color4;
					}
				}
			}

			&.facebook {
				height:rem(19px);

				a {
					display:inline-block;
					line-height:rem(19px);

					&:hover {
						opacity: 0.75;
					}
				}

				@include breakpoint(giant, max) {
					margin-right: rem(15px);
				}
			}

			i {
				vertical-align:text-bottom;
			}

			&.instagram {
				height:rem(19px);
				margin-left: rem(15px);
				padding: 0;

				a {
					display:inline-block;
					line-height:rem(19px);

					&:hover {
						opacity: 0.75;
					}
				}

				@include breakpoint(giant, max) {
					margin-left: rem(30px);
				}
			}

			i {
				vertical-align:text-bottom;
			}
		}
	}
}

span {
	&.instagram {
		svg {
			display: inline-block;
			margin-left: rem(10px);
		}
	}
}


.branding {
    display:block;
    text-align:center;

    @include breakpoint(giant) {
        display:inline-block;
    }

    img {
        width:auto;
    }
}


// MAIN CONTENT
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

main {
	display: block;
	overflow:hidden;

	p,
	ol,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}

	.row {
		margin-bottom:rem(40px);

		@include breakpoint(large) {
			margin-bottom:rem(80px);
		}
		@include breakpoint(giant) {
			margin-bottom:rem(140px);
		}
	}

	.imageBox {
		@include breakpoint(large) {
			padding-left:rem(30px);
			position: relative;
		}

		&:after {
			@include breakpoint(large) {
				content:'';
				position:absolute;
				left:-18px;
				top:50%;
				width:400%;
				height:1px;
				background:$secondary;
				z-index:0;
			}
		}

		* {
			position:relative;
			z-index:1;
		}
	}

	.image {
		@extend .marginBottom;
	}
}

.startIMG{
	@include breakpoint(small,max){
		display: none;
	}
}

.bgBox {
	background:$secondary;
	color:$light;
	padding:rem(30px) rem(30px) 11px;
	@extend .marginBottom;

	@include breakpoint(giant) {
		padding:rem(50px) rem(40px) rem(20px);
	}

	@include breakpoint(huge) {
		padding:rem(76px) rem(95px) rem(33px);
	}

	@include breakpoint(full) {
		padding:rem(116px) rem(135px) rem(73px);
	}

	&.small {
		@include breakpoint(giant) {
			padding-top:rem(96px);
			padding-bottom:rem(55px);
		}
	}

	* {
		color:$light;
	}

	a {
		color:$color4;

		* {
			color:$color4;
		}

		&.btn {
			color:$light;

			* {
				color:$light;
			}
		}
	}

	&.lineLeft {

		@include breakpoint(large) {
			position: relative;
		}

		&:after {
			@include breakpoint(large) {
				content:'';
				position:absolute;
				right:100%;
				top:50%;
				width:400%;
				height:1px;
				background:$secondary;
				z-index:1;
			}
		}
	}

	.row {
		margin-bottom:0;
	}

	body.fuhrpark & {
		a {
			img {
				margin-bottom: rem($baseLineHeight)!important;
			}
		}
		
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.teamBox {

	background-color: #f7f7f7;
	color: $primary;
	padding: rem(25px);
	font-size: rem(15px);
	line-height: rem(25px);
	margin-bottom: rem(25px);
	height: 100%;
	
	img {
		margin-bottom: rem(15px);
	}

	p {
		color: $dark;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.h4 {
		margin-bottom: rem(10px);
		text-align: center;
	}
}

.descriptionBox {
		display: block;
		background: $secondary;
		padding: rem(20px);
		color: $light;
		text-align: center;
		margin-top: rem(15px);
	}

	.copyright {
		width: rem(127px);
		height: rem(51px);
	}


// FOOTER
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

#pageFooter {
	padding:rem(50px) 0 0;

	@include breakpoint(giant) {
		padding:rem(100px) 0 0;
	}

	.bgBox {
		margin-bottom:rem(40px);

		@include breakpoint(giant) {
			margin-bottom:rem(170px);
		}

		span.title {
			display:block;
		    font-size:rem(19px);
		    line-height:rem(29px);
		    margin-bottom:rem(15px);

		    @include breakpoint(giant) {
		        font-size:rem(28px);
		        line-height:rem(36px);
		        margin-bottom:rem(30px);
		    }
		}

		p {
			@extend .marginBottom;
		}

		span.title, p {
	        body.index & {
	        	@include breakpoint(giant) {
		        	padding-right:rem(50px);
		        }

				@include breakpoint(huge) {
					padding-right:rem(70px);
				}

				@include breakpoint(full) {
					padding-right:rem(135px);
				}
	        }
		}

		a {
			color:$light;
		}

		.videoBox {
			margin-bottom:rem(30px);

			.video {
				display:block;
				background: {
			        image:image-url("layout/petras-und-steffis-fahrschulen-2.jpg");
			        position:center;
			        repeat:no-repeat;
			        size:cover;
			    };
			    padding:rem(40px);
			    color:$light;
			    text-decoration:none;
			    text-align:center;
			    font-size:rem(16px);
			    line-height:rem(26px);
			    position: relative;
			    transition:.5s;
			    cursor:pointer;

			    @include breakpoint(tiny) {
			    	padding:rem(80px) rem(40px);
			    }

			    @include breakpoint(medium) {
			    	padding:rem(314px) 0 rem(20px);
			    }

			    @include breakpoint(giant) {
			    	padding:rem(380px) 0 rem(20px);
			    }

			    &:hover {
					@include breakpoint(giant) {
						padding:rem(360px) 0 rem(40px);
					}

					&:after {
						@include breakpoint(giant) {
							opacity:.6;
						}
					}
			    }

			    &:after {
			    	content:'';
			    	position:absolute;
			    	top:0;
			    	left:0;
			    	right:0;
			    	bottom:0;
			    	background:rgba(#000,.7);
			    	transition:.5s;

			    	@include breakpoint(medium) {
			    		background:linear-gradient(to bottom, rgba(#000,0) 45%, rgba(#000,.9) 100%);
			    	}
			    }

			    * {
			    	color:$light;
			    }

			    span {
			    	position: relative;
			    	z-index:2;
			    }

			    i {
			    	vertical-align:text-bottom;
			    }
			}

			.popupToggler {
				display: none;

				&:checked {

					+ .popupBackground {
						opacity: 1;
						visibility: visible;

						+ .popupClose {
							opacity: 1;
							visibility: visible;

							+ .popupContainer {
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}
			}

			.popupBackground, .popupClose, .popupContainer {
				opacity:0;
				visibility:hidden;
				position:fixed;
				cursor:pointer;
			}

			.popupBackground {
				top:0;
				bottom:0;
				left:0;
				width:100%;
				z-index:2000;
				background:rgba(#000,0.85);
			}

			.popupClose {
				font-size:rem(50px);
				line-height:100%;
				color:$light;
				top:rem($baseGap);
				right:rem($baseGap);
				z-index:2002;
			}

			.popupContainer {
				padding:0 rem($baseGap);
				z-index:2001;
				width:rem(315px);
				height:rem(400px);
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				overflow:hidden;
				overflow-y:auto;
				display:flex;
				
				@include breakpoint(tiny) {
					width:rem(480px);
					height:rem(270px);
				}

				@include breakpoint(small) {
					width:rem(640px);
					height:rem(360px);
				}

				@include breakpoint(medium) {
					width:rem(760px);
					height:rem(428px);
				}

				@include breakpoint(large) {
					width:rem(992px);
					height:rem(559px);
				}

				.popupContent {
					display:flex;
					background:$light;
					position: relative;
					width: 100%;

					iframe {
						position:absolute;
						top:0;
						left:0;
						width:100%;
						height:100%;
					}

					p {
						width:100%;
						padding:rem(20px);
						display:block;
						color:$primary;
						text-align:center;
					    margin-bottom:0;
					    font-family:$mainFont;
					    font-weight:300;
					    font-size:rem(17px);
					    line-height:rem(27px);
					    background-image:none !important;

					    @include breakpoint(giant) {
					    	padding:rem(50px);
					    }

					    * {
					    	color:$primary;
					    }
					}
				}
			}
		}
	}

	.infoBox {
		font-size:rem(13px);
		line-height:rem(23px);
		margin-bottom:rem(50px);
		text-align:center;

		@include breakpoint(tiny) {
			font-size:rem(16px);
			line-height:rem(26px);
		}

		@include breakpoint(giant) {
			margin-bottom:rem(160px);
			text-align:left;
		}

		.branding {
			margin-bottom:rem(20px);

			@include breakpoint(giant) {
				margin-bottom:rem(32px)
			}
		}

		address {
			font-style:normal;
			display:block;
		}

		dl {
			display:flex;
			justify-content:center;
			flex-wrap:wrap;

			dt {
				font-weight:600;

				@include breakpoint(giant) {
					width:rem(70px);
				}
			}

			dd {
				padding-left:rem(6px);

				@include breakpoint(giant) {
					padding-left:0;
					width:calc(100% - 70px);
				}
			}

			a {
				text-decoration:none;
				transition:.5s;

				&:hover {
					color:$color4;
				}
			}
		}

		.naviAddBox {
			display:none;

			@include breakpoint(giant) {
				display:flex;
				justify-content:space-between;
				padding:rem(30px) rem(60px) 0 0;
			}

			@include breakpoint(huge) {
				padding-right:rem(100px);
			}

			@include breakpoint(full) {
				padding-right:rem($baseGap);
			}

			.navi {
				display:block;

				li {
					margin:0;
					font-size:rem(16px);
					line-height:rem(28px);

					&.hasSub > a {
						font-weight:600;
					}

					a {
						text-decoration:none;
						transition:.5s;

						&:hover {
							color:$color4;
						}

						&.active {
							color:$secondary;
							font-weight:600;
						}
					}

					ul {
						margin-bottom:rem(10px);
						position:relative;

						&:before {
							content:'';
							position:absolute;
							top:rem(5px);
							left:0;
							bottom:rem(6px);
							background:rgba($color5,.2);
							width:1px;
						}

						li {
							padding-left:rem(26px);
							font-size:rem(16px);
							line-height:rem(26px);

							a {

								&:before {
									font-family:$iconFont;
									content:'\f054';
									line-height:rem(10px);
									padding-right:rem(4px);
								}
							}
						}
					}
				}
			}
		}
	}

	.last {
		padding:rem(70px) 0;
		margin-bottom:rem($baseGap);
		background: {
	        image:image-url("layout/petras-und-steffis-fahrschulen-3.jpg");
	        position:center;
	        repeat:no-repeat;
	        size:cover;
	    };
	    position:relative;

	    @include breakpoint(tiny) {
	    	padding:rem(100px) 0;
	    }

	    @include breakpoint(giant) {
	    	margin-bottom:rem(150px);
	    	padding:rem(480px) rem(135px) rem(80px);
	    }

	    &:after {
	    	content:'';
	    	position:absolute;
	    	top:0;
	    	left:0;
	    	right:0;
	    	bottom:0;
	    	background:rgba($secondary,.7);
	    	z-index:1;

	    	@include breakpoint(giant) {
	    		background:linear-gradient(to bottom, rgba($secondary,0) 20%, rgba($secondary,.7) 100%);
	    	}
	    }

	    p {
	    	font-size:rem(16px);
	    	line-height:rem(26px);
	    	color:$light;
	    	position:relative;
	    	z-index:2;
	    	text-align:center;

	    	@include breakpoint(tiny) {
		    	font-size:rem(24px);
		    	line-height:rem(34px);
	    	}

	    	@include breakpoint(giant) {
	    		text-align:left;
		    	font-size:rem(46px);
		    	line-height:rem(56px);
	    	}
	    }
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.locations {
	margin-top:rem(60px);

	@include breakpoint(giant) {
		position:fixed;
		top:rem(190px);
		right:-350px;
		z-index:100;
		margin-top:0;
	}

	// @include breakpoint(full) {
	// 	right:-450px;
	// }

	.button {
		display:block;
		background:$secondary;
		color:$light;
		font-size:rem(16px);
		line-height:rem(26px);
		font-weight:600;
		text-align:center;
		padding:rem(15px);
		transition:.5s;

		@include breakpoint(giant) {
			transform:rotate(90deg);
			transform-origin:25px 25px; 
			width:rem(580px);
			height:rem(50px);
			position:relative;
			z-index:1007;
			cursor:pointer;
			padding:rem(10px);
			right: -180px;
		}

		@include breakpoint(full) {
			// transform-origin:25px 25px; 
			width:rem(600px);
			right: -200px;
		}

		i {
			font-weight:300;
			vertical-align:text-bottom;
		}
	}

	.locationsContent {
		background:$light;
		color:$color6;
		display:flex;
		flex-wrap:wrap;
		padding:rem(30px) rem($baseGap);
		text-align:center;

		@include breakpoint(giant) {
			position:absolute;
			top:0;
			right:-250%;
			transition:.5s;
			z-index:1006;
			width:rem(1105px);
			height:rem(580px);
			padding:rem(20px) rem(30px);
			box-shadow: 0 0 20px 0 rgba(#000,.05);
			text-align: left;
		}

		@include breakpoint(full) {
			height:rem(600px);
			width:rem(1300px);
			padding:rem(30px) rem(60px) 0;
		}

		.location {
			width:100%;
			font-size:rem(15px);
			line-height:rem(22px);
			margin-bottom:rem(30px);

			@include breakpoint(small) {
				width:50%;
			}

			@include breakpoint(large) {
				font-size:rem(16px);
				width:33.333%;
				margin-bottom:0;
			}

			address {
				font-style:normal;
				font-weight:600;
				margin-bottom:rem(10px);

				@include breakpoint(giant) {
					margin-bottom:rem(10px);
				}

				@include breakpoint(full) {
					margin-bottom:rem(5px);
				}
			}

			ul {
				margin-bottom:rem(10px);

				@include breakpoint(large) {
					margin-bottom:rem(22px);
				}

				@include breakpoint(giant) {
					margin-bottom:rem(10px);
				}

				@include breakpoint(full) {
					margin-bottom:rem(22px);
				}

				li {
					padding-right: rem($baseGap);
				}
			}
		}
	}

	&:hover {
		.locationsContent {
			@include breakpoint(giant) {
				right:rem(395px);
			}

			@include breakpoint(full) {
				right:24rem;
			}
		}

		.button {
			@include breakpoint(giant) {
				background:$color3;
			}
		}
	}
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 50px;
  border: 1px solid #888;
  width: 320px;
  margin-top: -52px;

  @include breakpoint(medium) {
  background-color: #fefefe;
  margin: auto;
  padding: 50px;
  border: 1px solid #888;
  width: 400px;
  margin-top: 100px;	
  }

  .btn {
  	margin: 0 0 15px !important;
  }

  h2 {
  	text-align: center;
  }

  .closepopupbtn {
  	float: right;
  	padding: 2px 12px;
  }
}

/* The Close Button */
.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}