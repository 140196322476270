// Form styles
form.default {
	width: 100%;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color:rgba($primary,.5);
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color:rgba($primary,.5);
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color:rgba($primary,.5) !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color:rgba($primary,.5);
	}

	fieldset {
		border: none;
		margin: 0 0 3.5rem 0;

		.flexBox {
			display: flex;
			flex-direction: column;

			@include breakpoint(small) {
				flex-direction: row;
				justify-content: space-between;
			}
			@include breakpoint(large) {
				width: 100%;
				flex-direction: column;
				justify-content: left;
			}
			@include breakpoint(giant) {
				flex-direction: row;
				justify-content: space-between;
			}

			.flexElement {
				width: 100%;

				@include breakpoint(small) {
					width: 48%;
				}
				@include breakpoint(large) {
					width: 100%;
				}
				@include breakpoint(giant) {
					width: 48%;
				}
			}
		}
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		min-height: 3.5rem;
	}

	select {
		option {
			color: $dark;
		}
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}

		&[for="Datenschutz"], &[for="Datenschutz2"]  {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 6rem;
		padding-top: 0.9rem;
	}

	[type="checkbox"] {
		display: inline-block;
		float: right;
		margin: 0 0 0.5rem rem(15px);
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
