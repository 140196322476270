* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	line-height: rem($baseLineHeight);
	font-weight:300;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight);
	overflow-x:hidden;
}

iframe, [data-iframe] {
	border: none;
	width: 100%;

	&[data-src] {
		@extend %lazyloading;
	}
}

b, strong {
	font-weight:600;
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
    font-weight:600;

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1, .h1 {
    font-size:rem(20px);
    line-height:rem(30px);
    margin-bottom:rem(20px);
    color:$secondary;

    @include breakpoint(giant) {
        font-size:rem(32px);
        line-height:rem(40px);
        margin-bottom:rem(26px);
    }
}

h2, .h2 {
    font-size:rem(19px);
    line-height:rem(29px);
    margin-bottom:rem(15px);
    color:$secondary;

    @include breakpoint(giant) {
        font-size:rem(28px);
        line-height:rem(36px);
        margin-bottom:rem(30px);
    }
}

h3, .h3 {
    font-size:rem(18px);
    line-height:rem(28px);
    margin-bottom:rem(10px);
    text-transform:uppercase;

    @include breakpoint(giant) {
        font-size:rem(23px);
        line-height:rem(33px);
        margin-bottom:rem(15px);
    }
}

h4, .h4 {
    font-size:rem(16px);
    line-height:rem(26px);
    margin-bottom:rem(10px);

    @include breakpoint(giant) {
        font-size:rem(22px);
        line-height:rem(32px);
        margin-bottom:rem(10px);
    }
}

h5, .h5, h6, .h6 {
    font-size:rem(16px);
    line-height:rem(26px);
    margin-bottom:rem(10px);

    @include breakpoint(giant) {
        font-size:rem(20px);
        line-height:rem(30px);
    }
}

/**
 * Links
 */
a {
	color: $primary;
	word-wrap: break-word;

	&:focus,
	&:hover,
	&:active {
		color: $secondary;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}

	&:hover {
		text-decoration:none;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr, .hr {
	display: flex;
	flex-basis: 100%;
	border:none;
	clear:both;
	height:1px;
	margin:2rem 0 4rem;
	background:rgba($secondary,.1);

	@include breakpoint(large) {
		margin:6rem 0 9rem;
	}

	&.last:last-child {
		display:none;
	}

	.col & {
		margin:2rem 0 4rem;

		@include breakpoint(large) {
			margin:3rem 0 3rem;
		}
	}
}

ul,
ol {
  list-style: none;

  &.styledList {

    li {
      padding-left: rem(19px);
      margin-bottom: rem(26px);

      &:before {
        @extend .icon;
        @extend .icon-check;
        text-indent: rem(-19px);
        vertical-align:text-bottom;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($color4,.1);
			padding: rem(15px);

			@include breakpoint(giant) {
				padding: rem(25px);
			}
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;

			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	list-style-type: none;
	counter-reset: item;

	li {
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		display: table;
		counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {
			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'], &[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
	}
}

/*
 * reset copyright link
 */
[href*="alpen-web.ch"],
[href*="bewertet.de"],
[href*="euroweb.at"],
[href*="euroweb.de"],
[href*="ew.de"],
[href*="geprueft.de"],
[href*="web2walk.com"],
[href*="webstyle.de"],
[href*="webstyle.com"],
[href*="wn-onlineservice.de"],
[href*="internet-media.com"],
[href*="yourrate.com"] {
	display: table;
	text-decoration: none;

	img {
		background: none;
		border-radius: 0;
		border: none;
		margin-bottom: rem(5px);
		outline: none;
		padding: 0;
		white-space: normal;
		width: auto !important;
	}
}

/**
 * Tables
 */

table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: rem($baseFontSize);

  @include breakpoint(small, max) {
    overflow-x: auto;
    display: block;
  }

  caption {
    text-align: left;
    padding: rem($baseGap) rem($baseGap) rem(25px);
    display: table-caption;
    font-weight: 700;
    font-size: 1.2rem;
  }

  thead {
    border-collapse: collapse;
    display: table-header-group;
  }

  tbody {
    width: 100%;
    overflow-x: scroll;
    display: table-row-group;

    tr {
      padding: rem($baseGap) 0;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $medium;
      }
    }
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: rem($baseGap);
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(small, max) {
    td {
      min-width: 50vw;
    }
  }
}
