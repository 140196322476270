// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 16px;

// Line height of main content (in px)
$baseLineHeight: 26px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "barlowlight";
$iconFont:    "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #000;
$secondary: #00245d;
$color3:    #2768aa;
$color4:    #87b6e6;
$color5:    #585756;
$color6:    #686868;

$light:     #fff;
$medium:    #F2F2F0;
$dark:      #262D3B;

$border:    #bbb;
$alert:     #f77723;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1620px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";


$iconMap: (
    times: "\f00d",
    bars: "\f0c9",
    minus: "\f068",
    angle-right: "\f054",
    plus: "\f067",
    angle-up: "\f077",
    exclamation: "\f06a",
    angle-left: "\ea24",
    arrow-down: "\ea1d",
    arrow-right: "\ea1f",
    play: "\ea29",
    map: "\ea3d",
    pen: "\ea7a",
    phone: "\ea81",
    check : "\eaa7"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    default: (
        width:  em(100px, $bpContext),
        cols: (6)
    ),
    tiny: (
        width:  em(480px, $bpContext),
        cols: (6)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (6)
    ),
    medium: (
        width:  em(760px, $bpContext),
        cols: (2,3,4,5,6,7,8,9,10)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (3,4,6,8,9,12)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (2,3,4,5,6,7,8,9,10)
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (6)
    ),
    full: (
        width:  em(1700px, $bpContext),
        cols: (5,7)
    )
);